// import React, { Component, useState } from "react"; // HotReload spots
import React, { Component } from "react";
import styled from "styled-components";
import { Bling as GPT } from "react-gpt";
import dayjs from "dayjs";
// import { v4 as uuidv4 } from "uuid"; // HotReload spots

import { AdContext } from "../../contexts/AdContext";

import Icon from "./Icon";

export default class AdManagerUnit extends Component {
  static contextType = AdContext;

  constructor(props) {
    super(props);

    this.state = {
      isAdClosed: false,
      spotData: null,
      activeCampaign: null,
      // key: uuidv4(), // HotReload spots
    };
  }

  componentDidMount() {
    this.updateSpotData();

    // setInterval(() => {
    //   this.setState((state) => ({
    //     key: uuidv4(),
    //   }));
    // }, 1000 * 20); // HotReload spots
  }

  componentDidUpdate() {
    this.updateSpotData();
  }

  updateSpotData() {
    if (this.state.isAdClosed) {
      this.setState({ ...this.state, isAdClosed: false });
    }

    const spotData = this.context.spots
      .filter((spot) => {
        return spot.campaignSpotKey === this.props.name;
      })
      .pop();

    if (JSON.stringify(this.state.spotData) !== JSON.stringify(spotData)) {
      this.setState(
        {
          spotData,
          activeCampaign: this.getActiveCampaign(spotData)[0],
        },
        () => {
          if (this.state.activeCampaign) {
            this.trackCustomAdImpression();
          }
        }
      );
    }
  }

  getActiveCampaign(spotData) {
    if (!spotData || !spotData.campaigns) return false;
    if (spotData.campaigns.length === 0) return false;

    return spotData.campaigns.filter((campaign) => {
    // const activeCampaign = spotData.campaigns.filter((campaign) => {
      const startDate = dayjs(campaign.campaignStartDate, "X");
      const endDate = dayjs(campaign.campaignEndDate, "X");
      const now = dayjs();

      if (
        startDate.diff(now) < 0 &&
        endDate.diff(now) > 0 &&
        campaign.campaignStatus
      ) {
        return true;
      }
    });

    // console.log("activeCampaign", activeCampaign);
    // return activeCampaign;
  }

  trackCustomAdClick = () => {
    const { activeCampaign, spotData } = this.state;

    if (activeCampaign) {
      window.dataLayer.push({
        event: `CustomAd-Click`,
        customAdSlotName: `${activeCampaign.campaignName}-click-${spotData.campaignSpotDescription}`,
        campaignName: activeCampaign.campaignName,
      });
    }
  };

  trackCustomAdImpression = () => {
    const { activeCampaign, spotData } = this.state;

    if (activeCampaign) {
      window.dataLayer.push({
        event: `CustomAd-Impression`,
        customAdSlotName: `${activeCampaign.campaignName}-impression-${spotData.campaignSpotDescription}`,
        campaignName: activeCampaign.campaignName,
      });
    }
  };

  render() {
    const { spotData, activeCampaign, isAdClosed } = this.state;
    // const { spotData, activeCampaign, isAdClosed, key } = this.state; // HotReload spots
    const { closeable } = this.props;

    if (isAdClosed) return null;

    if (!activeCampaign) {
      if (!spotData) return null;

      const [siprocalSizeArrayWidth, siprocalSizeArrayHeight] =
        spotData.siprocalSizeArray.split(",");
      const siprocalSizeWidthReturn = parseInt(
        siprocalSizeArrayWidth?.slice(2)
      );
      const siprocalSizeHeightReturn = parseInt(
        siprocalSizeArrayHeight?.slice(0, -2)
      );

      return (
        <AdmContainerGAM
          noMargin={this.props.noMargin}
          // minHeight={this.props.minHeight}
          className={this.props.className}
          // key={key} // HotReload spots
          hideMobile={spotData.campaignSpotKey === "BACKGROUND_TAKEOVER_LEFT" || spotData.campaignSpotKey === "BACKGROUND_TAKEOVER_RIGHT"}
        >
          {!!spotData.adManagerSlotName && !spotData.isSiprocal && (
            <Container>
              {/* {closeable ? (
              <CloseAdButton onClick={() => { this.setState({ ...this.state, isAdClosed: true }) }}>
                <Icon name="close" size="15" color="white" />
              </CloseAdButton>
            ) : null} */}
              <GPT
                adUnitPath={spotData.adManagerSlotName}
                slotSize={JSON.parse(spotData.adManagerSizeArray)}
                collapseEmptyDiv
                // key={key} // HotReload spots
              />
            </Container>
          )}

          {!!spotData.siprocalSlotName && !!spotData.isSiprocal && (
            <>
              <Container
                id={spotData.campaignSpotKey}
                dangerouslySetInnerHTML={{
                  __html: `
              <iframe
              marginheight="0"
              marginwidth="0"
              loading="eager"
              border="0"
              scrolling="no"
              frameBorder="0"
              width="${siprocalSizeWidthReturn}px"
              height="${siprocalSizeHeightReturn}px"
              srcDoc="<div><script
              src='https://arena.siprocalads.com/${spotData.siprocalSlotName}?us_privacy=[REPLACE]&gpp=[REPLACE]&gpp_sid=[REPLACE]'
              type='text/javascript'>
              </script><div>">
              </iframe>
              `,
                }}
              />
            </>
          )}
        </AdmContainerGAM>
      );
    }

    return (
      <AdmContainerCustom
        noMargin={this.props.noMargin}
        className={this.props.className}
        // key={key} // HotReload spots
        // minHeight={this.props.minHeight}
      >
        <Container>
          {closeable ? (
            <CloseAdButton
              onClick={() => {
                this.setState({ ...this.state, isAdClosed: true });
              }}
            >
              <Icon name="close" size="15" color="white" />
            </CloseAdButton>
          ) : null}
          <a
            onClick={this.trackCustomAdClick}
            href={activeCampaign.campaignLink}
            target="_blank"
            rel="noreferrer noopener"
            aria-label={activeCampaign.campaignName}
          >
            <img
              src={activeCampaign.campaignImage}
              alt={activeCampaign.campaignName}
            />
          </a>
        </Container>
      </AdmContainerCustom>
    );
  }
}

const AdmContainerCustom = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  // To test adSpots positions
  // border: 5px solid red;
  // background-color: red;

  margin: 0 auto;
  margin-bottom: ${(props) => (props.noMargin ? "0px" : "24px")};
`;

const AdmContainerGAM = styled(AdmContainerCustom)`
  @media only screen and (max-width: 1449px) {
  display: ${(props) => (props.hideMobile ? "none" : "flex")};
  }
`;

const Container = styled.div`
  position: relative;
`;

const CloseAdButton = styled.button`
  position: absolute;
  top: -12.5px;
  right: -12.5px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  fill: white;
  background: black;
`;

